<template>
    <label class="checkbox-switcher">
        <input type="checkbox" :name="name" @change="changeSwitcher">
        <div class="status"></div>
    </label>
</template>

<script>
    export default {
        name: "CheckboxSwitcher",
        props: ['name', 'event'],
        methods: {
            changeSwitcher(e) {
                this.$root.$emit('checkbox-switcher-change', {
                    name: this.name,
                    value: e.target.checked
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .checkbox-switcher {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .text {
            display: flex;
            align-items: center;
        }

        .icon {
            margin-right: 14px;
            font-size: 26px;
            position: relative;
            top: 1px;
            width: 30px;
        }

        .field-title {
            color: #ffffff;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.2;
        }

        .status {
            width: 66px;
            height: 30px;
            border-radius: 50px;
            background: #c9d2d6;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 3px;
                bottom: 3px;
                top: 3px;
                width: 20px;
                display: block;
                background: #0091d0;
                border: 2px solid #077aac;
                border-radius: inherit;
                transition: all .3s;
                box-shadow: 0 4px 10px transparentize(#0091d0, .42);
            }
        }

        input {
            display: none;

            &:checked {
                & + .status {

                    &:after {
                        left: calc(100% - 27px);
                    }
                }
            }
        }

    }
</style>