<template>
	<div id="app">
		<calculator/>
	</div>
</template>

<script>
	import HelloWorld from './components/HelloWorld.vue'
	import Calculator from "./components/Calculator";

	export default {
		name: 'App',
		components: {
			Calculator
		},
		mounted() {
			console.log('calc init');
		}
	}
</script>

<style lang="scss">

</style>
