<template>
	<div class="area-box">
		<img :src="require('../../assets/Areas/motion-cam-side-m.svg')">
		<div class="inner-object" :style="`width: ${objectWith}%; height: ${objectHeight}%`"></div>
	</div>
</template>

<script>
	export default {
		name: "MotionTopAreaM",
		props: ['width', 'height'],
		data() {
			return {
				widthConst: 10,
				heightConst: 11.33333333333333,
			}
		},
		computed: {
			objectWith() {
				return this.width / this.widthConst;
			},
			objectHeight() {
				return this.height / this.heightConst;
			}
		}
	}
</script>

<style scoped lang="scss">
	.area-box {
		.inner-object {
			left: 49.7%;
			bottom: 10%;
		}
	}
</style>