<template>
	<section class="calculator-section">
		<header class="section-header">
			<h2 class="section-title">{{ $t('title') }}</h2>
		</header>
		<article>
			<div class="calculator">
				<div class="controls-text">
					<h3>{{ $t('workspace-dimensions') }}
						<span class="tooltip">
			              <sup class="show-tooltip"><span class="icon icon-info-circle"></span></sup>
			              <div class="tooltip-content">
			                <p>{{ $t('workspace-info-bubble') }}</p>
			              </div>
			            </span>
					</h3>
					<div class="controls">
						<div class="controls-fields-image">
							<div class="controls-group">
								<div class="form-group with-range-slider">
									<label class="red">X - {{ $t('x') }}<small>(mm)</small></label>
									<vue-slider v-model="widthValue" :min="0" :max="3200" :tooltip="'none'"/>
									<div class="number-input-field">
										<input type="number" v-model="widthValue">
										<div class="number-input-actions">
											<button class="number-input-action" @click.prevent="widthValue++">+</button>
											<button class="number-input-action" @click.prevent="widthValue--">-</button>
										</div>
									</div>
								</div>
								<div class="form-group with-range-slider">
									<label class="green">Y - {{ $t('y') }}<small>(mm)</small></label>
									<vue-slider v-model="lengthValue" :min="0" :max="3200" :tooltip="'none'"/>
									<div class="number-input-field">
										<input type="number" v-model="lengthValue">
										<div class="number-input-actions">
											<button class="number-input-action" @click.prevent="lengthValue++">+
											</button>
											<button class="number-input-action" @click.prevent="lengthValue--">-
											</button>
										</div>
									</div>
								</div>
								<div class="form-group with-range-slider">
									<label class="blue">Z - {{ $t('z') }}<small>(mm)</small></label>
									<vue-slider v-model="heightValue" :min="0" :max="3200" :tooltip="'none'"/>
									<div class="number-input-field">
										<input type="number" v-model="heightValue">
										<div class="number-input-actions">
											<button class="number-input-action" @click.prevent="heightValue++">+
											</button>
											<button class="number-input-action" @click.prevent="heightValue--">-
											</button>
										</div>
									</div>
								</div>
							</div>
							<figure class="controls-preview">
								<img src="@/assets/3d.png">
							</figure>
						</div>
						<h3>{{ $t('device') }}</h3>
<!--						<div class="form-group with-checkbox-switcher">
							<p>MotionCam-3D</p>
							<checkbox-switcher name="device_type"/>
							<p>PhoXi 3D Scanner</p>
						</div>-->
						<div class="form-group choices">
							<label class="radio-box">
								<input type="radio" name="device_type" value="motion"
								       :checked="deviceType === 'motion'"
								       @change="changeDeviceType">
								<span class="status"></span>
								<span class="field-title">MotionCam-3D</span>
							</label>
							<label class="radio-box">
								<input type="radio" name="device_type" value="phoxi"
								       :checked="deviceType === 'phoxi'"
								       @change="changeDeviceType">
								<span class="status"></span>
								<span class="field-title">PhoXi 3D Scanner</span>
							</label>
							<label class="radio-box">
								<input type="radio" name="device_type" value="alpha"
								       :checked="deviceType === 'alpha'"
								       @change="changeDeviceType">
								<span class="status"></span>
								<span class="field-title">Alpha 3D Scanner</span>
							</label>
						</div>
						<!--						<div class="benefits-list-group">
													<ul class="benefits-list">
														<li>{{ $t('continuous-scanning') }} <span class="icon icon-badge-check"></span></li>
														<li>{{ $t('scanning-range') }} <span class="icon icon-badge-check"></span></li>
														<li>{{ $t('dynamic-scene') }}<span
															class="icon icon-badge-check"></span></li>
														<li>{{ $t('static-scene-2m') }} <span class="icon icon-badge-check"></span></li>
													</ul>
													<ul class="benefits-list">
														<li><span class="icon icon-badge-check"></span> {{ $t('single-scan') }}</li>
														<li><span class="icon icon-badge-check"></span> {{ $t('extra-volumes') }}</li>
														<li><span class="icon icon-badge-check"></span>{{ $t('static-scene-3m') }}</li>
													</ul>
												</div>-->
					</div>
				</div>
				<div class="area-type-container">
					<div class="recommended-device">
						<h4>{{ $t('scanning-volume') }} </h4>
						<h3>{{ deviceName }}</h3>
					</div>
					<div class="type-box">
						<div class="sizes-container">
							<label class="size-box" v-for="sizeObj in sizes" :key="sizeObj.value"
							       :class="getExtraSizeClass(sizeObj)"
							       v-if="checkIfExists(sizeObj)"
							>
								<input type="radio" name="size" v-model="size" :value="sizeObj.value"
								       @change="sendChangeEvent">
								<div class="status"></div>
								<span class="field-title">{{ getSizeName(sizeObj) }}</span>
							</label>
						</div>
					</div>
					<p class="error-message" v-if="showErrorMessage">{{ $t('error-message') }}</p>
					<div class="image-box">
						<figure>
							<img :src="deviceImage">
						</figure>
					</div>
					<div class="area-group" v-if="deviceType === 'motion'">
						<div class="area-container" v-if="size === 'xs'">
							<motion-top-area-xs :width="width" :height="height" :class="getExtraSizeClass(sizes[0])"/>
							<motion-side-area-xs :width="length" :height="height" :class="getExtraSizeClass(sizes[0])"/>
						</div>
						<div class="area-container" v-if="size === 's'">
							<motion-top-area-s :width="width" :height="height" :class="getExtraSizeClass(sizes[1])"/>
							<motion-side-area-s :width="length" :height="height" :class="getExtraSizeClass(sizes[1])"/>
						</div>
						<div class="area-container" v-if="size === 'm'">
							<motion-top-area-m :width="width" :height="height" :class="getExtraSizeClass(sizes[2])"/>
							<motion-side-area-m :width="length" :height="height" :class="getExtraSizeClass(sizes[2])"/>
						</div>
						<div class="area-container" v-if="size === 'l'">
							<motion-top-area-l :width="width" :height="height" :class="getExtraSizeClass(sizes[3])"/>
							<motion-side-area-l :width="length" :height="height" :class="getExtraSizeClass(sizes[3])"/>
						</div>
						<div class="area-container" v-if="size === 'xl'">
							<motion-top-area-xl :width="width" :height="height" :class="getExtraSizeClass(sizes[4])"/>
							<motion-side-area-xl :width="length" :height="height" :class="getExtraSizeClass(sizes[4])"/>
						</div>
						<div class="area-container" v-if="size === 'xxl'">
							<motion-top-area-xxl :width="width" :height="height" :class="getExtraSizeClass(sizes[5])"/>
							<motion-side-area-xxl :width="length" :height="height"
							                      :class="getExtraSizeClass(sizes[5])"/>
						</div>
					</div>
					<div class="area-group" v-else-if="deviceType === 'phoxi'">
						<div class="area-container" v-if="size === 'xs'">
							<phoxi-top-area-xs :width="width" :height="height" :class="getExtraSizeClass(sizes[0])"/>
							<phoxi-side-area-xs :width="length" :height="height" :class="getExtraSizeClass(sizes[0])"/>
						</div>
						<div class="area-container" v-if="size === 's'">
							<phoxi-top-area-s :width="width" :height="height" :class="getExtraSizeClass(sizes[1])"/>
							<phoxi-side-area-s :width="length" :height="height" :class="getExtraSizeClass(sizes[1])"/>
						</div>
						<div class="area-container" v-if="size === 'm'">
							<phoxi-top-area-m :width="width" :height="height" :class="getExtraSizeClass(sizes[2])"/>
							<phoxi-side-area-m :width="length" :height="height" :class="getExtraSizeClass(sizes[2])"/>
						</div>
						<div class="area-container" v-if="size === 'l'">
							<phoxi-top-area-l :width="width" :height="height" :class="getExtraSizeClass(sizes[3])"/>
							<phoxi-side-area-l :width="length" :height="height" :class="getExtraSizeClass(sizes[3])"/>
						</div>
						<div class="area-container" v-if="size === 'xl'">
							<phoxi-top-area-xl :width="width" :height="height" :class="getExtraSizeClass(sizes[4])"/>
							<phoxi-side-area-xl :width="length" :height="height" :class="getExtraSizeClass(sizes[4])"/>
						</div>
					</div>
					<div class="area-group" v-else>
						<div class="area-container" v-if="size === 'l'">
							<alpha-top-area-l :width="width" :height="height" :class="getExtraSizeClass(sizes[3])"/>
							<alpha-side-area-l :width="length" :height="height" :class="getExtraSizeClass(sizes[3])"/>
						</div>
						<div class="area-container" v-if="size === 'xl'">
							<alpha-top-area-xl :width="width" :height="height" :class="getExtraSizeClass(sizes[4])"/>
							<alpha-side-area-xl :width="length" :height="height" :class="getExtraSizeClass(sizes[4])"/>
						</div>
					</div>
					<div class="actions">
						<a href="https://www.photoneo.com/downloads/device-resources/" class="button button-tertiary"
						   target="_blank">{{ $t('datasheet') }}</a>
					</div>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
import MotionTopAreaXs from "./Areas/MotionTopAreaXs";
import MotionTopAreaS from "./Areas/MotionTopAreaS";
import MotionTopAreaM from "./Areas/MotionTopAreaM";
import MotionTopAreaL from "./Areas/MotionTopAreaL";
import MotionTopAreaXl from "./Areas/MotionTopAreaXl";
import MotionTopAreaXxl from "./Areas/MotionTopAreaXxl";
import MotionSideAreaXs from "./Areas/MotionSideAreaXs";
import MotionSideAreaS from "./Areas/MotionSideAreaS";
import MotionSideAreaM from "./Areas/MotionSideAreaM";
import MotionSideAreaL from "./Areas/MotionSideAreaL";
import MotionSideAreaXl from "./Areas/MotionSideAreaXl";
import MotionSideAreaXxl from "./Areas/MotionSideAreaXxl";
import PhoxiTopAreaXs from "./Areas/PhoxiTopAreaXs";
import PhoxiTopAreaS from "./Areas/PhoxiTopAreaS";
import PhoxiTopAreaM from "./Areas/PhoxiTopAreaM";
import PhoxiTopAreaL from "./Areas/PhoxiTopAreaL";
import PhoxiTopAreaXl from "./Areas/PhoxiTopAreaXl";
import PhoxiSideAreaXs from "./Areas/PhoxiSideAreaXs";
import PhoxiSideAreaS from "./Areas/PhoxiSideAreaS";
import PhoxiSideAreaM from "./Areas/PhoxiSideAreaM";
import PhoxiSideAreaL from "./Areas/PhoxiSideAreaL";
import PhoxiSideAreaXl from "./Areas/PhoxiSideAreaXl";
import AlphaTopAreaL from "@/components/Areas/AlphaTopAreaL";
import AlphaTopAreaXl from "@/components/Areas/AlphaTopAreaXl";
import AlphaSideAreaL from "@/components/Areas/AlphaSideAreaL";
import AlphaSideAreaXl from "@/components/Areas/AlphaSideAreaXl";
import CheckboxSwitcher from "./Fields/CheckboxSwitcher";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
	name: "Calculator",
	components: {
		MotionTopAreaXs,
		MotionTopAreaS,
		MotionTopAreaM,
		MotionTopAreaL,
		MotionTopAreaXl,
		MotionTopAreaXxl,
		MotionSideAreaXs,
		MotionSideAreaS,
		MotionSideAreaM,
		MotionSideAreaL,
		MotionSideAreaXl,
		MotionSideAreaXxl,
		PhoxiTopAreaXs,
		PhoxiTopAreaS,
		PhoxiTopAreaM,
		PhoxiTopAreaL,
		PhoxiTopAreaXl,
		PhoxiSideAreaXs,
		PhoxiSideAreaS,
		PhoxiSideAreaM,
		PhoxiSideAreaL,
		PhoxiSideAreaXl,
		AlphaTopAreaL,
		AlphaTopAreaXl,
		AlphaSideAreaL,
		AlphaSideAreaXl,
		CheckboxSwitcher,
		VueSlider,
	},
	data() {
		//get param from url
		let urlParams = new URLSearchParams(window.location.search);
		let width_value = urlParams.get('width');
		let height_value = urlParams.get('height');
		let length_value = urlParams.get('length');
		let deviceType = urlParams.get('deviceType');
		let size = urlParams.get('size');


		return {
			widthValue: width_value ? width_value : 300,
			heightValue: height_value ? height_value : 400,
			lengthValue: length_value ? length_value : 300,
			size: size ? size : 'l',
			deviceType: deviceType ? deviceType : 'motion',
			sizes: [
				{
					value: 'xs',
					max_width_phoxi: 106,
					max_width_motion: 106,
					max_height_phoxi: 44,
					max_height_motion: 44,
					max_length_phoxi: 70,
					max_length_motion: 70,
					size_name_phoxi: 'xs',
					size_name_motion: 's',
					image_phoxi: 'phoxi-xs.jpg',
					image_motion: 'motion-s-s+.jpg',
					threshold_phoxi: 5,
					threshold_motion: 5,
					max_z_phoxi: 44,
					max_z_motion: 558,
					max_x_phoxi: 133,
					max_x_motion: 507,
					max_y_phoxi: 88,
					max_y_motion: 192,
					a_top_phoxi: -3.259259,
					a_top_motion: -1.634043,
					d_top_phoxi: 3.409219,
					d_top_motion: 1.915749,
					a_side_phoxi: -4.888889,
					a_side_motion: -3.459459,
					d_side_phoxi: 4.990114,
					d_side_motion: 3.601091,
					vz_phoxi: 44,
					vz_motion: 192,
				},
				{
					value: 's',
					max_width_phoxi: 343,
					max_width_motion: 343,
					max_height_phoxi: 136,
					max_height_motion: 136,
					max_length_phoxi: 237,
					max_length_motion: 249,
					size_name_phoxi: 's',
					size_name_motion: 's+',
					image_phoxi: 'phoxi-s.jpg',
					image_motion: 'motion-s-s+.jpg',
					threshold_phoxi: 10,
					threshold_motion: 40,
					max_z_phoxi: 136,
					max_z_motion: 944,
					max_x_phoxi: 382,
					max_x_motion: 1349,
					max_y_phoxi: 319,
					max_y_motion: 968,
					a_top_phoxi: -6.974359,
					a_top_motion: -2.175115,
					d_top_phoxi: 7.045685,
					d_top_motion: 2.393977,
					a_side_phoxi: -3.317073,
					a_side_motion: -3.329806,
					d_side_phoxi: 3.464531,
					d_side_motion: 3.476724,
					vz_phoxi: 136,
					vz_motion: 944,
				},
				{
					value: 'm',
					max_width_phoxi: 590,
					max_width_motion: 590,
					max_height_phoxi: 660,
					max_height_motion: 660,
					max_length_phoxi: 292,
					max_length_motion: 303,
					size_name_phoxi: 'm',
					size_name_motion: 'm',
					image_phoxi: 'phoxi-m.jpg',
					image_motion: 'motion-m-m+.jpg',
					threshold_phoxi: 30,
					threshold_motion: 30,
					max_z_phoxi: 660,
					max_z_motion: 442,
					max_x_phoxi: 826,
					max_x_motion: 862,
					max_y_phoxi: 686,
					max_y_motion: 567,
					a_top_phoxi: -2.593320,
					a_top_motion: -1.664783,
					d_top_phoxi: 2.779444,
					d_top_motion: 1.942036,
					a_side_phoxi: -3.350254,
					a_side_motion: -3.637860,
					d_side_phoxi: 3.496312,
					d_side_motion: 3.772801,
					vz_phoxi: 660,
					vz_motion: 442,
				},
				{
					value: 'l',
					max_width_phoxi: 1082,
					max_width_motion: 1082,
					max_width_alpha: 1044,
					max_height_phoxi: 1280,
					max_height_motion: 1280,
					max_height_alpha: 1280,
					max_length_phoxi: 557,
					max_length_motion: 568,
					max_length_alpha: 531,
					size_name_phoxi: 'l',
					size_name_motion: 'm+',
					size_name_alpha: 'l',
					image_phoxi: 'phoxi-l.jpg',
					image_motion: 'motion-m-m+.jpg',
					image_alpha: 'alpha-l.jpg',
					threshold_phoxi: 80,
					threshold_motion: 40,
					threshold_alpha: 90,
					max_z_phoxi: 1280,
					max_z_motion: 944,
					max_z_alpha: 1280,
					max_x_phoxi: 1644,
					max_x_motion: 1317,
					max_x_alpha: 1595,
					max_y_phoxi: 1326,
					max_y_motion: 951,
					max_y_alpha: 1261,
					a_top_phoxi: -2.452107,
					a_top_motion: -2.180139,
					a_top_alpha: -2.5073646,
					d_top_phoxi: 2.648175,
					d_top_motion: 2.398542,
					d_top_alpha: 2.699404,
					a_side_phoxi: -3.328999,
					a_side_motion: -3.476980,
					a_side_alpha: -3.506849,
					d_side_phoxi: 3.475951,
					d_side_motion: 3.617926,
					d_side_alpha: 3.646641,
					vz_phoxi: 1280,
					vz_motion: 944,
					vz_alpha: 1280,
				},
				{
					value: 'xl',
					max_width_phoxi: 1954,
					max_width_motion: 1954,
					max_width_alpha: 1889,
					max_height_phoxi: 2100,
					max_height_motion: 2100,
					max_height_alpha: 2100,
					max_length_phoxi: 1076,
					max_length_motion: 1090,
					max_length_alpha: 1018,
					size_name_phoxi: 'xl',
					size_name_motion: 'l',
					size_name_alpha: 'xl',
					image_phoxi: 'phoxi-xl.jpg',
					image_motion: 'motion-l.jpg',
					image_alpha: 'alpha-xl.jpg',
					threshold_phoxi: 100,
					threshold_motion: 70,
					threshold_alpha: 90,
					max_z_phoxi: 2100,
					max_z_motion: 2256,
					max_z_alpha: 2100,
					max_x_phoxi: 3072,
					max_x_motion: 2495,
					max_x_alpha: 2988,
					max_y_phoxi: 2349,
					max_y_motion: 1836,
					max_y_alpha: 2231,
					a_top_phoxi: -2.210526,
					a_top_motion: -2.164029,
					a_top_alpha: -2.254428,
					d_top_phoxi: 2.426196,
					d_top_motion: 2.383909,
					d_top_alpha: 2.466262,
					a_side_phoxi: -3.299293,
					a_side_motion: -3.423369,
					a_side_alpha: -3.462490,
					d_side_phoxi: 3.447511,
					d_side_motion: 3.566434,
					d_side_alpha: 3.604003,
					vz_phoxi: 2100,
					vz_motion: 2256,
					vz_alpha: 2100,
				},
				{
					value: 'xxl',
					max_width_motion: 1656,
					max_height_motion: 2480,
					max_length_motion: 926,
					size_name_motion: 'l+',
					image_motion: 'motion-l+.jpg',
					threshold_motion: 90,
					max_z_motion: 2480,
					max_x_motion: 3277,
					max_y_motion: 2640,
					a_top_motion: -2.126018,
					d_top_motion: 2.349458,
					a_side_motion: -2.893816,
					d_side_motion: 3.061726,
					vz_motion: 2480,
				},
			],

		}
	},
	computed: {
		showErrorMessage() {
			let item = this.getSelectedDeviceObject();
			if (!item) {
				return false;
			}
			let extraClass = this.getExtraSizeClass(item);
			if (extraClass !== 'not-recommended' && extraClass !== 'not-recommended active') {
				return false;
			}

			return true;
		},
		width() {
			this.addParamToUrl('width', this.widthValue);
			return parseInt(this.widthValue);
		},
		height() {
			this.addParamToUrl('height', this.heightValue);
			return parseInt(this.heightValue);
		},
		length() {
			this.addParamToUrl('length', this.lengthValue);
			return parseInt(this.lengthValue);
		},
		deviceImage() {
			let chosenSize = this.sizes.find(item => item.value === this.size);
			if (this.deviceType === 'motion') {
				return require(`@/assets/img/${chosenSize.image_motion}`);
			} else if(this.deviceType === 'phoxi'){
				return require(`@/assets/img/${chosenSize.image_phoxi}`);
			}

			return require(`@/assets/img/${chosenSize.image_alpha}`);
		},

		deviceName() {
			if (this.deviceType === 'motion') {
				return 'MotionCam-3D';
			} else if (this.deviceType === 'phoxi') {
				return 'PhoXi 3D Scanner';
			}

			return 'Alpha 3D Scanner';
		}
	},
	methods: {
		changeDeviceType(e){
			let device = e.target.value;
			if(device === 'phoxi' && this.size === 'xxl'){
				this.size = 'xl';
			}
			if (device === 'alpha'){
				if (['xs', 's', 'm'].includes(this.size)){
					this.size = 'l';
				}
				if (this.size === 'xxl'){
					this.size = 'xl';
				}
			}

			this.deviceType = device;
			this.addParamToUrl('deviceType', this.deviceType);
		},

		addParamToUrl(param, value) {
			let url = new URL(window.location.href);
			url.searchParams.set(param, value);
			window.history.pushState({}, '', url.href);
		},
		selectRecommendedSize() {
			let recommendedSize = false;
			this.sizes.forEach(size => {
				if (this.deviceType === 'motion') {
					if (this.width <= size.max_width_motion && this.height <= size.max_height_motion && this.length <= size.max_length_motion && !recommendedSize) {
						this.size = size.value;
						recommendedSize = true;
					}
				} else if (this.deviceType === 'phoxi') {
					if (this.width <= size.max_width_phoxi && this.height <= size.max_height_phoxi && this.length <= size.max_length_phoxi && !recommendedSize) {
						this.size = size.value;
						recommendedSize = true;
					}
				} else {
					if (this.width <= size.max_width_alpha && this.height <= size.max_height_alpha && this.length <= size.max_length_alpha && !recommendedSize) {
						this.size = size.value;
						recommendedSize = true;
					}
				}
			});
		},
		getSelectedDeviceObject() {
			return this.sizes.find((item => item.value === this.size));
		},
		getSizeName(item) {
			if (this.deviceType === 'motion') {
				return item.size_name_motion;
			} else if (this.deviceType === 'phoxi') {
				return item.size_name_phoxi;
			}

			return item.size_name_alpha;
		},
		getDistTop(item) {
			if (this.deviceType === 'motion') {
				return (item.a_top_motion * (item.max_x_motion - this.width) / 2 + this.height) / item.d_top_motion;
			} else if (this.deviceType === 'phoxi') {
				return (item.a_top_phoxi * (item.max_x_phoxi - this.width) / 2 + this.height) / item.d_top_phoxi;
			} else {
				return (item.a_top_alpha * (item.max_x_alpha - this.width) / 2 + this.height) / item.d_top_alpha;
			}
		},
		getDistSide(item) {
			if (this.deviceType === 'motion') {
				return (item.a_side_motion * (item.max_y_motion - this.length) / 2 + this.height) / item.d_side_motion;
			} else if (this.deviceType === 'phoxi') {
				return (item.a_side_phoxi * (item.max_y_phoxi - this.length) / 2 + this.height) / item.d_side_phoxi;
			} else {
				return (item.a_side_alpha * (item.max_y_alpha - this.length) / 2 + this.height) / item.d_side_alpha;
			}
		},
		getDistZ(item) {
			if (this.deviceType === 'motion') {
				return this.height - item.vz_motion;
			} else if (this.deviceType === 'phoxi') {
				return this.height - item.vz_phoxi;
			} else {
				return this.height - item.vz_alpha;
			}
		},
		getOverflow(item) {
			let values = [
				this.getDistTop(item),
				this.getDistSide(item),
				this.getDistZ(item)
			];

			return Math.max(...values);
		},
		getExtraSizeClass(item) {
			let overflow = this.getOverflow(item);

			let extraClass = '';

			if (this.deviceType === 'motion') {
				if (overflow > item.threshold_motion) {
					extraClass = 'not-recommended';
				} else if (overflow > 0 && overflow <= item.threshold_motion) {
					extraClass = 'optionally-recommended';
				} else if (overflow <= 0) {
					extraClass = 'recommended';
				}
			} else if (this.deviceType === 'phoxi') {
				if (overflow > item.threshold_phoxi) {
					extraClass = 'not-recommended';
				} else if (overflow > 0 && overflow <= item.threshold_phoxi) {
					extraClass = 'optionally-recommended';
				} else if (overflow <= 0) {
					extraClass = 'recommended';
				}
			} else {
				if (overflow > item.threshold_alpha) {
					extraClass = 'not-recommended';
				} else if (overflow > 0 && overflow <= item.threshold_alpha) {
					extraClass = 'optionally-recommended';
				} else if (overflow <= 0) {
					extraClass = 'recommended';
				}
			}

			if (this.size === item.value) {
				extraClass += ' active';
			}

			return extraClass;
		},

		checkIfExists(item) {
			return !!item[`size_name_${this.deviceType}`];
		},

		sendChangeEvent() {
			let event = new CustomEvent('device-changed', {
				detail: {
					size: this.size,
					device_type: this.deviceType
				}
			});
			this.addParamToUrl('deviceType', this.deviceType);

			document.body.dispatchEvent(event);
		}

	},
	mounted() {
		if (this.deviceType === 'phoxi') {
			this.$root.$emit('checkbox-switcher-change', {
					size: this.size,
					device_type: this.deviceType
				},
				this.sendChangeEvent
			);

			let label = document.querySelector('.checkbox-switcher');
			label.click();
		}

		this.$root.$on('checkbox-switcher-change', (data) => {
			if (data.name === 'device_type') {
				if (data.value) {
					this.deviceType = 'phoxi';
				} else {
					this.deviceType = 'motion';
				}

				if (this.deviceType === 'phoxi' && this.size === 'xxl') {
					this.size = 'xl';
				}

				this.sendChangeEvent();
			}
		});
	}
}
</script>

<style lang="scss">
$blackColor: #000000;
$whiteColor: #ffffff;
$primaryColor: #1792ce;
$successColor: #36b648;
$disabledColor: #c9d2d6;
$backgroundColor: #f8f9fa;
$fontColor: #7989a6;
$fontDarkColor: #29333c;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
* {
	box-sizing: border-box;
}

.error-message {
	text-align: center;
	color: #D91A21;
	line-height: 1.6;
	margin-bottom: 35px;
}


.tooltip {
	position: relative;

	.show-tooltip {
		color: $primaryColor;
		transition: all .3s;
		font-size: 16px;
		cursor: pointer;

		&:hover {
			color: $blackColor;

			+ .tooltip-content {
				opacity: 1;
			}
		}
	}

	.tooltip-content {
		position: absolute;
		opacity: 0;
		pointer-events: none;
		width: 400px;
		padding: 20px;
		background: $backgroundColor;
		left: calc(50% - 200px);
		bottom: calc(100% + 25px);
		transition: .3s all;
		box-shadow: 5px 5px 20px transparentize($blackColor, .9);

		&:after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 10px 0 10px;
			border-color: $backgroundColor transparent transparent transparent;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: -8px;
		}

		p {
			line-height: 1.6;
			margin: 0 0 25px;

			&:last-child {
				margin-bottom: 0;
			}
		}

	}

	@media(max-width: 768px) {
		.tooltip-content {
			width: 250px;
			left: auto;
			right: 0;

			&:after {
				left: auto;
				right: 0;
				bottom: -12px;
				border-width: 0 16px 12px 0;
				border-color: transparent $backgroundColor transparent transparent;
			}
		}
	}
	@media(max-width: 480px) {
		display: none;
	}
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-moz-appearance: textfield;

}

.calculator-section {
	font-family: 'Montserrat', sans-serif;

	.section-header {
		margin-bottom: 50px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.section-title {
		text-align: center;
		margin-bottom: 35px;
		font-size: 42px;
		font-weight: 500;
		color: $blackColor;

		&:last-child {
			margin-bottom: 0;
		}

		@media(max-width: 650px) {
			font-size: 30px;
		}
	}

	figure {
		margin: 0;
		text-align: center;

		img {
			max-width: 100%;
			vertical-align: middle;
		}
	}

	h1, h2, h3, h4 {
		line-height: 1.2;
	}

	@media(max-width: 991px) {
		flex-direction: column;
		.controls-text {
			width: 100%;
			max-width: 600px;
			margin: 0 auto 35px;
		}
		.area-type-container {
			padding-top: 0;
			width: 100%;
			max-width: 600px;
			margin: 0 auto;
		}
	}
}
.form-group.choices {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 50px;
	.radio-box {
		flex: 1;
	}
	@media(max-width: 480px){
		flex-direction: column;
		gap: 15px;
	}
}
.controls .form-group .radio-box {
	display: flex;
}
.radio-box {
	input {
		display: none;
	}
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	.status {
		display: block;
		width: 20px;
		height: 20px;
		border: 2px solid #077aac;
		border-radius: 50%;
		margin-bottom: 5px;
	}
	input:checked +.status {
		background: $primaryColor;
	}
}

.recommended-device {
	text-align: center;

	h4 {
		color: $primaryColor;
		font-weight: 600;
		margin: 0 0 10px;
		font-size: 30px;
		@media(max-width: 480px) {
			font-size: 26px;
		}
	}

	h3 {
		font-weight: 600;
		color: $blackColor;
		font-size: 30px;
		margin: 0 0 10px;
		@media(max-width: 480px) {
			font-size: 26px;
		}
	}
}

.benefits-list-group {
	display: flex;
	justify-content: center;

	.benefits-list {
		width: 50%;
		margin: 0;
		list-style-type: none;
		font-size: 16px;
		font-weight: 400;
		color: $fontColor;
		line-height: 1.666;
		padding: 0;

		&:first-child li {
			justify-content: flex-end;

			.icon {
				margin-left: 5px;
				margin-right: 0;
			}
		}

		li {
			display: flex;

			.icon {
				margin-right: 5px;
				color: $successColor;
			}
		}

		li + li {
			margin-top: 10px;
		}

		&:first-child {
			padding-right: 48px;
			text-align: right;
		}

		&:last-child {
			padding-left: 48px;
		}

		@media(max-width: 480px) {
			font-size: 14px;
		}
	}
}

.sizes-container {
	display: flex;
	justify-content: center;
	margin-bottom: 25px;

	.size-box {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 66px;
		height: 42px;
		font-weight: 700;
		font-size: 16px;
		color: #ffffff;
		background: $primaryColor;
		margin: 5px;
		text-transform: uppercase;
		border-radius: 25px;
		position: relative;
		cursor: pointer;
		padding-bottom: 8px;
		transition: opacity .3s;

		&.active {
			border-radius: 20px 20px 0 0;
		}

		&:hover {
			opacity: .8;
		}

		.status {

			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			top: 100%;
			opacity: 0;
			height: 20px;
		}

		input {
			display: none;

			&:checked {
				+ .status {
					opacity: 1;
				}
			}
		}

		&:after {
			font-size: 8px;
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 6px;
			font-weight: 400;
			text-align: center;
		}

		&.not-recommended:after {
			content: 'NOK';
		}

		&.optionally-recommended:after,
		&.recommended:after {
			content: 'OK';
		}

		&.not-recommended {
			background: $disabledColor;

			.status {
				background: linear-gradient(to bottom, $disabledColor 0%, transparentize($disabledColor, .99));
			}
		}

		&.optionally-recommended {
			background: #ffcb05;

			.status {
				background: linear-gradient(to bottom, #ffcb05 0%, transparentize(#ffcb05, .99));
			}
		}

		&.recommended {
			background: $successColor;

			.status {
				background: linear-gradient(to bottom, $successColor 0%, transparentize($successColor, .99));
			}
		}


		/*&:first-child:not(.disabled),
		&.disabled + .size-box:not(.disabled) {
		  background: $successColor;

		  .status {
			border-color: $successColor transparent transparent transparent;
		  }
		}

		&.recommended {
		  background: $successColor;

		  .status {
			border-color: $successColor transparent transparent transparent;
		  }
		}

		&.disabled {
		  background: $disabledColor;

		  .status {
			border-color: $disabledColor transparent transparent transparent;
		  }
		}*/
	}

}

.calculator {
	display: flex;
	@media(max-width: 991px) {
		flex-direction: column;
	}
}

.vue-slider-rail {
	height: 5px;
	background: #c9d2d6;
}

.vue-slider-dot-handle {
	border: 1px solid #077aac;
	background: #0091d0;
}

.controls-text {
	//width: calc(100% - 600px);
	width: 55%;
	padding: 35px 35px 50px;
	background: $backgroundColor;
	@media(max-width: 480px) {
		padding: 30px 30px 35px;
	}
	@media(max-width: 325px) {
		padding: 25px 20px 25px;
	}

	h3 {
		font-size: 30px;
		font-weight: 600;
		line-height: 1.2;
		text-align: center;
		color: $primaryColor;
		margin: 0 auto 35px;
		@media(max-width: 480px) {
			font-size: 26px;
		}

	}

	.form-group {
		label {
			font-weight: 400;
			color: $fontDarkColor;
			font-size: 18px;

			&.red {
				color: #D91A21;
			}

			&.green {
				color: #36B648;
			}

			&.blue {
				color: $primaryColor;
			}
		}

		margin-bottom: 25px;

		&:last-child {
			margin-bottom: 0;
		}

		input[type="text"],
		input[type="number"] {
			color: $fontColor;
			font-size: 18px;
			border: 1px solid #c3c3c3;
			border-radius: 5px;
			background: $whiteColor;
			outline: none;
			padding: 14px 15px 13px;
			line-height: 1;
			max-width: 110px;
			text-align: center;
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;


			@media(max-width: 480px) {
				font-size: 16px;
				padding: 10px 10px 8px;
				max-width: 95px;
			}
		}

		input[type="number"] {
			-moz-appearance: textfield;
			appearance: textfield;
		}

		&.with-range-slider {
			display: flex;
			justify-content: space-between;
			align-items: center;

			label {
				margin-bottom: 0;
				width: 90px;

				small {
					display: block;
					text-align: center;
					font-size: 12px;
				}

				@media(max-width: 1220px) {
					width: 80px;
					font-size: 16px;
				}
				@media(max-width: 480px) {
					width: 70px;
					font-size: 14px;
				}
				@media(max-width: 325px) {
					font-size: 12px;
					width: 60px;
				}
			}

			.vue-slider {
				flex-grow: 1;
				margin: 0 25px;
			}
		}

		&.with-checkbox-switcher {
			display: flex;
			justify-content: center;
			align-items: center;

			.checkbox-switcher {
				max-width: 66px;
				margin: 0 15px;
			}

			p {
				margin: 0 0 5px;
				flex: 1;
				font-weight: 600;
				font-size: 18px;
				color: $blackColor;

				&:first-child {
					text-align: right;
				}

				@media(max-width: 480px) {
					font-size: 15px;
				}
			}
		}
	}
}

.controls-text .form-group {
	.number-input-field {
		input {
			padding: 14px 45px 13px 10px;
			@media(max-width: 480px) {
				padding: 10px 35px 8px 10px;
			}
		}
	}
}

.number-input-field {
	position: relative;

	.number-input-actions {
		position: absolute;
		width: 35px;
		top: 1px;
		bottom: 1px;
		right: 1px;
		overflow: hidden;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		@media(max-width: 480px) {
			width: 25px;
		}
	}

	.number-input-action {
		position: absolute;
		width: 100%;
		height: 50%;
		border: none;
		outline: none;
		color: $primaryColor;
		cursor: pointer;
		text-align: center;
		font-size: 18px;
		border-left: 1px solid #c3c3c3;
		transition: .3s all;

		&:hover {
			background: $primaryColor;
			color: $whiteColor;
		}

		&:first-child {
			top: 0;
			border-bottom: 1px solid #c3c3c3;
		}

		&:last-child {
			bottom: 0;
		}
	}
}

.benefits {

}

.area-type-container {
	//width: 600px;
	padding-top: 35px;
	width: 45%;
	margin-left: 35px;

	.actions {
		margin-top: 35px;
		text-align: center;
	}
}

.controls {
	display: flex;
	flex-direction: column;
	max-width: 1440px;
	margin: 0 auto;

	* {
		box-sizing: border-box;
	}


	.controls-fields-image {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 35px;

		.controls-group {
			width: 75%;
		}

		.controls-preview {
			width: 25%;
			margin-left: 10px;
			margin-bottom: 5%;
		}

		@media(max-width: 480px) {
			flex-direction: column;
			.controls-preview {
				margin-left: 0;
				margin-top: 25px;
				width: 100%;
				margin-bottom: 0;
			}
			.controls-group {
				width: 100%;
			}
		}
	}


	.form-group {
		flex: 1;
		margin-bottom: 30px;

		label {
			margin-bottom: 5px;
			display: block;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.area-container {
	display: flex;
	justify-content: space-around;

	.area-box {
		width: 50%;
	}

	@media(max-width: 480px) {
		flex-direction: column;
		.area-box {
			width: 100%;
			margin-bottom: 25px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.area-box {
	margin: 0 auto;
	max-width: 500px;
	position: relative;
	overflow: hidden;

	&:before {
		display: block;
		content: '';
		padding-bottom: 100%;
	}

	img,
	svg {
		left: 50%;
		top: 50%;
		position: absolute;
		height: 100%;
		width: auto;
		z-index: 1;
		transform: translate(-50%, -50%);
	}

	img {
		pointer-events: none;
		max-width: 100%;
	}

	&.optionally-recommended {

		.inner-object {
			background: #ffcb05;
		}
	}

	&.recommended {
		.inner-object {
			background: #36B648;
		}

	}

	&.not-recommended {
		.inner-object {
			background: #D91A21;
		}
	}

	.inner-object {
		left: 50%;
		position: absolute;

		opacity: .7;
		//mix-blend-mode: difference;
		z-index: 2;
		transform: translateX(-50%);
	}
}
</style>
