<template>
	<div class="area-box" :class="extraClass">
		<img :src="require('../../assets/Areas/motion-cam-top-xs.svg')" alt="">
		<div class="inner-object" :style="`width: ${objectWith}%; height: ${objectHeight}%`"></div>
	</div>
</template>

<script>
	export default {
		name: "MotionTopAreaXs",
		props: ['width', 'height', 'extraClass'],
		data() {
			return {
				widthConst: 8.073248407643312,
				heightConst: 8.132147395171537,
			}
		},
		computed: {
			objectWith() {
				return this.width / this.widthConst;
			},
			objectHeight() {
				return this.height / this.heightConst;
			}
		}
	}
</script>

<style scoped lang="scss">
	.area-box {
		.inner-object {
			bottom: 9.62%;
			left: 57.6%;
		}
	}
</style>