import Vue from 'vue'
import VueI18n from 'vue-i18n'

//use vue-i18n
Vue.use(VueI18n)

function loadLocaleMessages () {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}
var locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
export default new VueI18n({
    locale: (locale.trim().length && locale != "/") ? locale : 'en' ,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
})