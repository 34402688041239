<template>
	<div class="area-box">
		<img :src="require('../../assets/Areas/motion-cam-top-s.svg')">
		<div class="inner-object" :style="`width: ${objectWith}%; height: ${objectHeight}%`"></div>
	</div>
</template>

<script>
	export default {
		name: "MotionTopAreaS",
		props: ['width', 'height'],
		data() {
			return {
				widthConst: 19.41007194244604,
				heightConst: 19.38398357289528,
			}
		},
		computed: {
			objectWith() {
				return this.width / this.widthConst;
			},
			objectHeight() {
				return this.height / this.heightConst;
			}
		}
	}
</script>

<style scoped lang="scss">
	.area-box {
		.inner-object {
			bottom: 6.54%;
			left: 55.35%;
		}
	}
</style>